@tailwind base;
@tailwind components;
@tailwind utilities;

App {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bouncing-loader {
    display: flex;
    justify-content: center;
    margin: 10px 10px;
    width: 100%;
  }
  
  .bouncing-loader > div.bulb {
    width: 12px;
    height: 12px;
    margin: 1px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  .bouncing-loader > div.hint {
    margin: 1px 15px 5px 15px;
    color: #999;
  } 

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-6px);
    }
  }
  
  .bouncing-loader > div.bulb:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div.bulb:nth-child(3) {
    animation-delay: 0.4s;
  }




  /* Answer / Feedback */
  .question, .answer {
    padding: 10px;

  }
  .answer:hover, .question:hover {
    background: #eee;

  }
  .answer .feedback, .answer .feedback select {
    display: none;

  }
  .answer:hover .feedback, .answer:hover .feedback select, .feedback.active {
    display: inline;
}
.feedback {
    padding: 0 20px;
}
.feedback > * {
  display: inline;
  cursor: pointer;
  width: 48px;
  margin-left: 10px;
  padding: 4px 5px;
  border-radius: 24px;
}
.feedback > span {
  display: inline;

}
.feedback svg {
  color: #999;
}
.feedback svg.positive:hover {
  color: green;
  background: white;
}
.feedback svg.negative:hover {
  color: red;
  background: white;
}
.feedback select {
  min-width: 360px;
}



/* link row */
.link-row {
  opacity: 0.5;
  padding: 5px;
}
.link-row:hover {
  opacity: 1;
  background: #f9f9f9;
}
.link-row {
  width: 100%;
  text-align: center;
}
.link-row a {
  color: green;
}
.link-row a:hover {
  text-decoration: underline;
}
.link-row span {
  padding: 0 10px;
}


/* conversation start */
.conversation-start {
  width: 100%;
  text-align: center;
  padding: 5px;
}